import logo from './logo.svg';
import './App.css';
import './styles.css';
import Store from './components/store';
import styled from 'styled-components';

const Title = styled.p`
    font-size: 2.5em;
    color: #00aaff;
`;

const SubTitle = styled.p`
    margin: 2rem;
    color: #748ab7;
    font-size: 1.5em;
`;

const ImageWrapper = styled.img`
    border-radius: 20px;
`;

const FeaturedCoral = styled.div`
    background: #000s;
    border-radius: 20px;
    padding: 12px;
    margin-top: 12px;
    cursor: pointer;
`;

const App = () => {
    return (
        <div className="App">
            <>
                <nav class="navbar">
                    <div class="nav-container">
                        <ul class="nav-links">
                            <li>
                                <a href="home.html">Home</a>
                            </li>
                            <li>
                                <a href="store.html">Shop</a>
                            </li>
                            <li>
                                <a href="contact.html">Contact</a>
                            </li>
                            <li>
                                <a href="info.html">Information</a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <main>
                    <header>
                        <Title>Welcome to Acropora</Title>
                        <SubTitle>
                            Sharing knowledge about aquaculture and natural
                            habitat of Acropora coral species
                        </SubTitle>

                        <ImageWrapper src="img/pcrainbow.jpg" width="80%" />
                    </header>
                    <section class="featured-corals">
                        <Title>Featured Corals</Title>
                        <FeaturedCoral>
                            <SubTitle>Caroliniana</SubTitle>
                            <ImageWrapper
                                src="img/caroliniana.jpg"
                                width="80%"
                            />
                        </FeaturedCoral>

                        <FeaturedCoral>
                            <SubTitle>TNT Anacropora</SubTitle>
                            <ImageWrapper
                                src="img/anacropora.jpg"
                                width="80%"
                            />
                        </FeaturedCoral>
                    </section>
                </main>
            </>
        </div>
    );
};

export default App;
